/*
 * Constants
 ***************************/
 var $ = jQuery.noConflict()
 const mobileBreakpoint = 768;
 let scrollTop = $(window).scrollTop();
 let fontsLoadedIntervalChecker = false;

/*
 * Globals
 ***************************/
  /* Window */
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var windowScrollHeight = document.querySelector('body').scrollHeight;
  /* Breakpoints */
    var isMobile = ( windowWidth <= mobileBreakpoint );
  /* Navigation */
    var isMenuOpen = false;

  /* Slick Options */
    const prevArrow = '<button type="button" class="test slick-big-arrow slick-prev"><span class="screen-reader-text">Previous</span></button>';
    const nextArrow = '<button type="button" class="slick-big-arrow slick-next"><span class="screen-reader-text">Next</span></button>';


const handleKeypressADA = function() {
  const keycode = (event.keyCode ? event.keyCode : event.which);
  if (keycode == '13') return;
  $(this).trigger('click');
};

$(window).on('load', function() {
  windowWidth = $(window).width();
  windowHeight = $(window).height();
  windowScrollHeight = document.querySelector('body').scrollHeight;
});

$(document).ready(function(){
  /* Generic */
  if ( $('.smooth-scroller').length ) { // internal links, smooth scrolling
    $('.smooth-scroller').on('click', function(e){
      e.preventDefault();
      let target = $( $(this).attr('href') ).offset().top - 148;
      let dist = Math.abs($(window).scrollTop() - target);

      $([document.documentElement, document.body]).animate({
        scrollTop: target
      }, Math.min(1200, ((dist / 1200) * 1000)));
    }).on('keypress', handleKeypressADA);
  }
  /* END GENERIC SCRIPTS */

  /*
  HERO ANIMATION
  */
  $('.st-anim').css('opacity', 0);
  fontsLoadedIntervalChecker = setInterval(function(){
    if ( document.fonts.check("6.4rem Amstelvar") ) {
      $('.st-anim').each(function(){
        new SplitText($(this).get(0), {
          type: 'lines',
          linesClass: 'st-anim__line-inner'
        });

        $(this).find('.st-anim__line-inner').each(function(){
          $(this).wrap('<div class="st-anim__line"></div>');
        });
      });

      gsap.set(".st-anim__line-inner", {y: '175%', rotate: '6deg'});
      $('.st-anim').css('opacity', 1);

      $('.st-anim').each(function(){
        ScrollTrigger.batch($(this).find(".st-anim__line-inner"), {
          onEnter: batch => {
            gsap.to(batch, {
              y: '0%',
              rotate: '0deg',
              duration: 0.6,
              stagger: {
                each: 0.1,
                onComplete: function(){
                  $(this.targets()[0]).addClass('active');
                }
              },
              ease: 'power1.out',
              stagger: 0.15
            })
          },
          // you can also define most normal ScrollTrigger values like start, end, etc.
          start: "20px bottom",
          end: "top top",
          once: true,
        });
      });


      clearInterval(fontsLoadedIntervalChecker);
    }
  }, 100);
  // end hero animation

  // section titles
  $('.site-section__title-inner').each(function(){
    const innerRef = $(this);
    const outerRef = $(this).closest('.site-section__title');
    const section = $(this).closest('.site-section');
    const span = $(this).children().get(0);
    const skewThese = [span];
    while (innerRef.width() < outerRef.width() * 3) {
      const newSpan = span.cloneNode(true);
      innerRef.append(newSpan);
      skewThese.push(newSpan);
    }

    let proxy = { skew: 0 },
    skewSetter = gsap.quickSetter(skewThese, "skewX", "deg"), // fast
    clamp = gsap.utils.clamp(-40, 40); // don't let the skew go beyond 20 degrees.

    gsap.to(innerRef, {
      scrollTrigger: {
        trigger: section,
        start: '20px bottom',
        end: 'bottom top',
        scrub: true,
        onUpdate: (self) => {
          let skew = clamp(self.getVelocity() / -300);
          // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
          if (Math.abs(skew) > Math.abs(proxy.skew)) {
            proxy.skew = skew;
            gsap.to(proxy, {skew: 0, duration: 0.8, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
          }
        }
      },
      x: '-50%'
    });
  });

  // resume table
  $('.work-section__table tr').each(function(){
    gsap.set(this, {
      y: 64,
      opacity: 0
    });

    gsap.to(this, {
      scrollTrigger: {
        start: '40px bottom',
        end: 'top top',
        once: true,
        trigger: this
      },
      y: 0,
      opacity: 1
    });
  });

  // tools list
  $('.work-section__tools li').each(function(){
    $(this).css('transform', 'translateY(48px) rotate('+ (Math.random() * 18 - 9)  +'deg) scale(0.8)');
    $(this).css('opacity', 0);
  });

  ScrollTrigger.batch(".work-section__tools li", {
    onEnter: batch => {
      gsap.to(batch, {
        y: '0',
        rotate: '0deg',
        opacity: 1,
        scale: 1,
        duration: 0.3,
        stagger: {
          each: 0.05,
        },
        ease: 'power1.out',
      })
    },
    // you can also define most normal ScrollTrigger values like start, end, etc.
    start: "20px bottom",
    end: "top top",
    once: true,
  });

  // showcase animations
  $('.showcase-flow__list li').each(function(){
    gsap.from($(this).children(), {
      y: '100%',
      rotate: ((Math.random() * 18) - 9) + 'deg',
      scrollTrigger: {
        trigger: this,
        start: '20px bottom',
        end: 'center center',
        scrub: 1.2
      }
    });
  });

  // contact form
  $('.site-contact__form-message').on('change', function(){
    $('.site-contact__form-submit').attr('href', 'mailto:maya.arguell@gmail.com?body=' + encodeURIComponent($(this).val()))
  });

}); // document ready end

/*
 * Window Resize
 ***************************/
$(window).resize(function(){
  windowWidth = $(window).width();
  windowHeight = $(window).height();
  windowScrollHeight = document.querySelector('body').scrollHeight;
  isMobile = ( windowWidth <= mobileBreakpoint );
}); // end window resize functions

/*
 * Window Scroll
 ***************************/
$(window).on('scroll', function() {
  scrollTop = $(this).scrollTop();
}); // end window scroll functions

/*
 * Functions
 ***************************/
